import React from 'react';

const Title = () => {
  return (
    <div className="title">
      <div className="logo">
        <h1>RSRM</h1>
      </div>
      <h2>Rico Suave Resma Monton</h2>
      <p className="date-display">December 10, 1962 &#8211; December 30, 2021</p>
      <div className="box"></div>
    </div>
  )
}

export default Title;