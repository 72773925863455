  import * as firebase from 'firebase/app';
  import 'firebase/storage';
  import 'firebase/firestore';
  
  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyAxzLS7BLKyUr8LaJhDBHJFUo4XqvGLyfo",
    authDomain: "rico-suave-121062.firebaseapp.com",
    projectId: "rico-suave-121062",
    storageBucket: "rico-suave-121062.appspot.com",
    messagingSenderId: "1092129602324",
    appId: "1:1092129602324:web:0bafa63252b5c7b3c8a001"
  };

//   // Initialize Firebase
//   const app = initializeApp(firebaseConfig);


// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp }
//   import * as firebase from 'firebase/app';
//   import 'firebase/storage';
//   import 'firebase/firestore';
  
//   // Your web app's Firebase configuration
//   const firebaseConfig = {
//     apiKey: "AIzaSyBdg51tEXeu4DuZ39dfWisOvwlwnydjSdk",
//     authDomain: "firegram-336210.firebaseapp.com",
//     projectId: "firegram-336210",
//     storageBucket: "firegram-336210.appspot.com",
//     messagingSenderId: "678762660922",
//     appId: "1:678762660922:web:06fe78f3b1aaf68afc6103"
//   };

// //   // Initialize Firebase
// //   const app = initializeApp(firebaseConfig);


// // Initialize Firebase
// firebase.initializeApp(firebaseConfig);

// const projectStorage = firebase.storage();
// const projectFirestore = firebase.firestore();
// const timestamp = firebase.firestore.FieldValue.serverTimestamp;

// export { projectStorage, projectFirestore, timestamp }